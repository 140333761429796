var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    {
      attrs: { icon: "mdi-school-outline", title: "Professores" },
      scopedSlots: _vm._u([
        {
          key: "tools",
          fn: function() {
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: { color: "error darken-1", icon: "" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      _vm.showFilterTeacher = true
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("v-icon", [
                                _vm._v("mdi-account-multiple-check-outline")
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Imprimir Extrato dos Professores")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: { color: "error darken-1", icon: "" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      _vm.showExtractSetting = true
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-cog-box")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Configurações do Extrato do Professor")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: { color: "teal darken-1", icon: "" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      _vm.showMailGeneralCommunication = true
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-email-send-outline")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Enviar Email de Comunicação Geral")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    color: "success darken-1",
                                    icon: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.download(19, "show_data=true")
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("v-icon", [_vm._v("mdi-account-cash-outline")])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Carga Horária Paga")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    color: "success darken-1",
                                    icon: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      _vm.showTimeSheet = true
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-printer-check")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Imprimir Diário de Aula")])]
              ),
              _c("emc-excel-import-icon", {
                attrs: { param: "teachers_complete" },
                on: {
                  onCompleted: function($event) {
                    return _vm.getData()
                  }
                }
              }),
              !_vm.isAnima()
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          color: "success darken-1",
                                          icon: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.download(2)
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-microsoft-excel")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3343209334
                      )
                    },
                    [_c("span", [_vm._v(" Relatório (Ativos) ")])]
                  )
                : _vm._e(),
              !_vm.isAnima()
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          color: "primary darken-1",
                                          icon: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.download(
                                              2,
                                              "allTeachers=true"
                                            )
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-microsoft-excel")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        197336416
                      )
                    },
                    [_c("span", [_vm._v(" Relatório (Ativos e Inativos) ")])]
                  )
                : _vm._e(),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: { color: "indigo darken-1", icon: "" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      _vm.showForm = true
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-plus")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v(" Cadastrar Novo Professor ")])]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("emc-loader-progress", { attrs: { show: _vm.loadingProgress } }),
      _c("emc-table-items", {
        attrs: {
          headers: _vm.headers,
          params: _vm.params,
          collections: _vm.collections,
          meta: _vm.meta,
          itemDelete: _vm.itemDelete,
          itemEdit: _vm.model,
          loading: _vm.loading,
          find: true,
          label: "Pesquisar por nome ou cpf (apenas números)..."
        },
        on: {
          "update:params": function($event) {
            _vm.params = $event
          },
          "update:collections": function($event) {
            _vm.collections = $event
          },
          "update:meta": function($event) {
            _vm.meta = $event
          },
          "update:itemDelete": function($event) {
            _vm.itemDelete = $event
          },
          "update:item-delete": function($event) {
            _vm.itemDelete = $event
          },
          "update:itemEdit": function($event) {
            _vm.model = $event
          },
          "update:item-edit": function($event) {
            _vm.model = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item.name",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("emc-avatar-show-image", {
                  attrs: { model: item },
                  on: {
                    click: function($event) {
                      return _vm.showAvatar(item)
                    }
                  }
                }),
                _vm._v(" " + _vm._s(item.name) + " ")
              ]
            }
          },
          {
            key: "item.email",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "span",
                                _vm._g(_vm._b({}, "span", attrs, false), on),
                                [
                                  item.emails.length > 1
                                    ? _c("span", [
                                        _vm._v(_vm._s(item.email) + " "),
                                        _c("strong", [
                                          _vm._v(
                                            "(+" +
                                              _vm._s(item.emails.length - 1) +
                                              ")"
                                          )
                                        ])
                                      ])
                                    : _c("span", [_vm._v(_vm._s(item.email))])
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          item.emails
                            .map(function(o) {
                              return o["email"]
                            })
                            .join("<br/>")
                        )
                      }
                    })
                  ]
                )
              ]
            }
          },
          {
            key: "item.campuses",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "span",
                                _vm._g(_vm._b({}, "span", attrs, false), on),
                                [_vm._v(_vm._s(_vm.getCampuses(item)))]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.campuses
                              .map(function(o) {
                                return o["name"]
                              })
                              .join(", ")
                          ) +
                          " "
                      )
                    ])
                  ]
                )
              ]
            }
          },
          {
            key: "footer.prepend",
            fn: function() {
              return [
                _c("v-switch", {
                  staticClass: "ml-3",
                  attrs: { inset: "", label: "Exibir professores a contratar" },
                  model: {
                    value: _vm.onlyHiring,
                    callback: function($$v) {
                      _vm.onlyHiring = $$v
                    },
                    expression: "onlyHiring"
                  }
                }),
                _c("v-switch", {
                  staticClass: "ml-3",
                  attrs: { inset: "", label: "Com Vínculo Administrativo" },
                  model: {
                    value: _vm.onlyAdmin,
                    callback: function($$v) {
                      _vm.onlyAdmin = $$v
                    },
                    expression: "onlyAdmin"
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "actions",
            fn: function(ref) {
              var item = ref.item
              return [
                !_vm.onlyHiring && !_vm.onlyAdmin
                  ? _c("emc-table-icon-download-pdf", {
                      attrs: {
                        toolTip: "Extrato do Professor",
                        type: "teacher-extract",
                        item: item
                      }
                    })
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _c("sys-print-time-sheet-card", {
        attrs: { show: _vm.showTimeSheet },
        on: {
          "update:show": function($event) {
            _vm.showTimeSheet = $event
          }
        }
      }),
      _c("sys-register-teacher", {
        attrs: {
          "only-hiring": _vm.onlyHiring,
          model: _vm.model,
          icon: "event",
          show: _vm.showForm
        },
        on: {
          onCompleted: _vm.getData,
          "update:show": function($event) {
            _vm.showForm = $event
          }
        }
      }),
      _c("sys-teacher-extract-setting", {
        attrs: { show: _vm.showExtractSetting },
        on: {
          "update:show": function($event) {
            _vm.showExtractSetting = $event
          }
        }
      }),
      _c("sys-teacher-filter", {
        attrs: { show: _vm.showFilterTeacher },
        on: {
          "update:show": function($event) {
            _vm.showFilterTeacher = $event
          }
        }
      }),
      _c("sys-mail-general-communication", {
        attrs: { show: _vm.showMailGeneralCommunication },
        on: {
          "update:show": function($event) {
            _vm.showMailGeneralCommunication = $event
          }
        }
      }),
      _c("emc-alert-modal", {
        attrs: { text: _vm.textDelete, disabled: _vm.deleting, show: _vm.show },
        on: {
          "update:show": function($event) {
            _vm.show = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "error",
                      loading: _vm.deleting,
                      disabled: _vm.deleting
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.deleteItem()
                      }
                    }
                  },
                  [
                    _vm._v("Excluir "),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-delete")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-snackbar", {
        attrs: {
          show: !!_vm.message.length,
          message: _vm.message,
          color: _vm.error ? "error" : "success"
        }
      }),
      _c("emc-avatar-show", {
        attrs: {
          model: _vm.selectedTeacher,
          show: _vm.showImage,
          source: _vm.selectedTeacher ? _vm.selectedTeacher.url_avatar : null
        },
        on: {
          "update:show": function($event) {
            _vm.showImage = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }